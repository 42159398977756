body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.metrics {
  display: flex;
  justify-content: space-around;
  margin: 20px 0;
}

.metrics div {
  background: #f5f5f5;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}

.MuiDrawer-paper {
  width: 240px;
}
