/* EditProgramManagerModal.css */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.EditProgramManagerModal-modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 24%;
  max-width: 500px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  max-height: fit-content;
}

.modal-title {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: left;
}

.form-group {
  margin-bottom: 15px;
  text-align: left;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: 600;
}

.form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.btn-primary {
  border: solid 1px #007bff;
  background-color: white;
  color: #007bff;
}

.btn-primary:hover {
  background-color: #d5e7fc;;
}

.modal-close {
 border: solid 1px #007bff;
  background-color: white;
  color: #007bff;
  width: 48%;
}

.modal-close:hover {
  background-color: #d5e7fc;
}

.button-group {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 600px) {
  .modal-content {
    padding: 10px;
    width: 95%;
  }
}
