.admindash-dashboard {
  display: flex;
  height: 100vh;
}

.admindash-sidebar {
  width: 250px;
  background: #f5f5f5;
  padding: 20px;
}

.admindash-logo-container {
  text-align: center;
  margin-bottom: 20px;
}

.admindash-logo img {
  width: 80px;
}

.admindash-nav-container {
  margin-top: 20px;
  padding: 10px;
}

.admindash-nav ul {
  list-style: none;
  padding: 0;
}

.admindash-nav li {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.admindash-nav ul li {
  padding: 10px 0;
  cursor: pointer;
  font-size: 16px;
  color: #7b7b7b;
}

.admindash-nav li .admindash-nav-icon {
  margin-right: 10px;
}

.admindash-nav li.active {
  color: #909090;
  font-weight: bold;
}

.admindash-main-content {
  flex-grow: 1;
  padding: 20px;
  background-color: #ffffff;
}

.admindash-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.admindash-header .admindash-search-bar {
  width: 300px;
  padding: 10px 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.admindash-header .admindash-profile-section {
  display: flex;
  align-items: center;
}

.admindash-header .admindash-profile-section .admindash-notification-icon {
  margin-right: 15px;
  cursor: pointer;
}

.admindash-header .admindash-profile-section .admindash-user-info {
  display: flex;
  align-items: center;
}

.admindash-header .admindash-profile-section .admindash-user-initials {
  margin-right: 10px;
}

.admindash-header .admindash-profile-section .admindash-user-info .admindash-user-details {
  text-align: right;
}

.admindash-header .admindash-profile-section .admindash-user-info .admindash-user-name {
  display: flex;
  align-items: center;
}

.admindash-content-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.admindash-add-founder-button {
 color: white;
  background-color: #0183ff;
  border: 2px solid #0183ff;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.admindash-add-founder-button:hover{
  color: #007bff;
  background-color: #fff;
  border: 2px solid #0183ff;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.admindash-admin-list {
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow-x: auto;
}

.admindash-admin-list table {
  width: 100%;
  border-collapse: collapse;
}

.admindash-admin-list th,
.admindash-admin-list td {
  padding: 15px;
  text-align: left;
  border-bottom: 1px solid #eee;
}

.admindash-admin-list th {
  background: #f9f9f9;
}

.admindash-admin-list tr:hover {
  background-color: #F5FAFF;
  cursor: pointer;
}

.admindash-admin-list th input,
.admindash-admin-list td input {
  margin: 0;
}

.admindash-table-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  background: #f9f9f9;
}

.admindash-export-button {
  background: none;
  color: #111112;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.admindash-export-button .admindash-icon {
  margin-right: 5px;
}

.admindash-export-button:hover {
  background-color: #F5FAFF;
}

.admindash-pagination {
  display: flex;
  align-items: center;
}

.admindash-pagination-button {
  background: #fff;
  color: #1c88f8;
  padding: 5px 10px;
  border: 1px solid #1c88f8;
  cursor: pointer;
  margin: 0 5px;
  border-radius: 4px;
}

.admindash-pagination-button.disabled {
  color: #ccc;
  border-color: #ccc;
  cursor: not-allowed;
}

.admindash-pagination-info {
  margin: 0 10px;
  color: grey;
  font-size: 12px;
}

.admindash-rows-per-page {
  display: flex;
  align-items: center;
  font-size: 13px;
}

.admindash-rows-per-page select {
  margin-left: 5px;
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

/* Modal specific styles */
.admindash-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  overflow: auto; /* Add this line */
}

.admindash-modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 30%; /* Adjust this value if necessary */
  max-width: 400px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  max-height: fit-content;/* Add this line */
  overflow-y: auto; /* Add this line */
}

.admindash-modal-title {
  margin-bottom: 20px;
  margin-top: 20px;
  font-size: 18px;
  text-align: center;
}

.admindash-form-group {
  margin-bottom: 15px;
}

.admindash-form-group label {
  display: block;
  margin-bottom: 0px;
  text-align: left;
}

.admindash-form-group input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.admindash-btn-primary {
  background-color: #1c88f8;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 20%;
  margin-top: 20px;
}

.admindash-modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
}

.admindash-success-modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 25%;
  max-width: 400px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow-y: auto;
  text-align: center;
}

.admindash-success-modal-title {
  margin-bottom: 20px;
  font-size: 18px;
  text-align: center;
}

.admindash-success-modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
}
