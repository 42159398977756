


.email-modal-overlay-emailmodal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.email-modal-container-emailmodal {
  background: white;
  padding: 25px;
  border-radius: 8px;
  width: 80%;
  max-width: 100%;
  max-height: 92vh;
  display: flex;
  flex-direction: column;
  text-align: center;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  position: relative; /* Keep toolbar inside modal */
  overflow: hidden; /* Prevent toolbar overflow */
}

/* Scrollable modal content */
.email-modal-content-emailmodal {
  flex-grow: 1;
  overflow-y: auto;
  padding-right: 10px;
  max-height: 70vh;
}
.email-modal-close-button-emailmodal {
  position: absolute;
  top: 10px;
  right: 15px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: rgb(182, 182, 182);
}

.email-modal-close-button-emailmodal:hover {
  background-color: #ffffff;
  color: #000000;
}
.email-modal-content-emailmodal::-webkit-scrollbar {
  width: 6px;
}

.email-modal-content-emailmodal::-webkit-scrollbar-thumb {
  background-color: #007bff;
  border-radius: 5px;
  cursor: move;
}
.email-modal-title-emailmodal {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 15px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
}
.email-modal-main-title-emailmodal {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 15px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  color: #3f3f3f;
}

.email-modal-label-emailmodal {
  display: block;
  font-weight: bold;
  margin-top: 10px;
  text-align: left;
  font-weight: 500;
  font-family: "Inter", sans-serif;
}

.email-modal-input-emailmodal {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  font-family: "Inter", sans-serif;
  transition: border 0.3s ease-in-out;
  outline: none;
  color: #434343;
}

.email-modal-input-emailmodal:focus {
  border: 1px solid #007bff !important;
  outline: none;
}

.email-modal-quill-editor-emailmodal {
  margin-top: 20px;
  background: white;
  border: 1px solid #ededed;
  border-radius: 5px;
  font-family: "Inter", sans-serif;
  min-height: 280px;
  overflow-y: auto;
}

.ql-toolbar {
  border-radius: 5px 5px 0 0;
}

.email-modal-container-emailmodal .ql-toolbar-emailmodal {
  border-radius: 0 0 5px 5px;
  min-height: 250px;
}

.email-modal-container-emailmodal .ql-editor {
  min-height: 230px;
}

.email-modal-buttons-emailmodal {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 10px;
}
.email-modal-buttons-emailmodal button:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}
.email-modal-send-button-emailmodal {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  font-family: "Inter", sans-serif;
}

.email-modal-send-button-emailmodal:hover {
  background-color: #0056b3;
}

.email-modal-cancel-button-emailmodal {
  background-color: #ffffff;
  color: #007bff;
  border: 1px solid #007bff;
  padding: 10px 35px;
  cursor: pointer;
  border-radius: 5px;
  font-family: "Inter", sans-serif;
}

.email-modal-cancel-button-emailmodal:hover {
  background-color: #e6f7ff;
}

.ql-align-center {
  text-align: center;
}

.ql-align-right {
  text-align: right;
}

.ql-align-justify {
  text-align: justify;
}

.ql-indent-1 {
  margin-left: 20px;
}

.ql-indent-2 {
  margin-left: 40px;
}

.ql-indent-3 {
  margin-left: 60px;
}

.ql-editor a {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.ql-editor a:hover {
  color: darkblue;
}

@media (max-width: 500px) {
  .email-modal-container-emailmodal {
    width: 95%;
    max-width: 350px;
    padding: 15px;
  }

  .email-modal-main-title-emailmodal {
    font-size: 14px;
    text-align: center;
    line-height: 1.4;
    word-wrap: break-word;
    white-space: normal;
    padding: 0 5px;
  }

  .email-modal-buttons-emailmodal button {
    font-size: 14px;
    width: 100%;
    padding: 8px;
  }

  .email-modal-title-emailmodal {
    font-size: 16px;
  }

  .email-modal-input-emailmodal {
    font-size: 14px;
    padding: 5px;
  }

  .email-modal-quill-editor-emailmodal {
    min-height: 180px;
  }

  .email-modal-buttons-emailmodal button {
    width: 100%;
    padding: 8px;
  }

  .email-modal-close-button-emailmodal {
    font-size: 1.2rem;
    top: 5px;
    right: 10px;
  }
}

@media (max-width: 768px) {
  .email-modal-container-emailmodal .ql-container {
    min-height: 160px;
    width: 100%;
    overflow-x: hidden;
  }

  .email-modal-title-emailmodal {
    font-size: 16px;
  }

  .email-modal-input-emailmodal {
    font-size: 14px;
    padding: 6px;
  }

  .email-modal-quill-editor-emailmodal {
    min-height: 180px;
    font-size: 14px;
    width: 100%;
  }

  .email-modal-main-title-emailmodal {
    font-size: 16px;
    text-align: center;
    line-height: 1.4;
  }
}

.email-modal-toggle-container-emailmodal {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 12px;
}

.email-modal-toggle-label-emailmodal {
  font-size: 14px;
  font-weight: 500;
  color: #333;
  font-family: "Inter", sans-serif;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
}

.email-modal-checkbox-emailmodal {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.email-modal-dropdown-container-emailmodal {
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.email-modal-dropdown-emailmodal {
  width: 400px;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  background-color: white;
  cursor: pointer;
  transition: border-color 0.2s ease-in-out;
}

.email-modal-dropdown-emailmodal:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}


