/* Modal.css */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  position: relative;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}


.modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-body h2 {
  margin: 15px 0;
}

.modal-body p {
  margin-bottom: 20px;
}

.submit-btn {
  padding: 10px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  width: 40%;
}

.submit-btn:hover {
  background-color: #0056b3;
}
