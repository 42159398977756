.modal-container-addpostermodal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Stylish dark background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content-addpostermodal {
    background-color: #fff;
    padding: 30px;
    border-radius: 12px; /* Rounded corners */
    width: 400px;
    text-align: center;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2); /* Drop shadow for added style */
    font-family: 'Arial', sans-serif;
  }
  
  .modal-content-addpostermodal h2 {
    margin-top: 0;
    font-size: 1.5rem;
    color: #333;
  }
  
  .modal-content-addpostermodal input[type="file"] {
    display: block;
    margin: 20px auto;
    font-size: 1rem;
    padding: 6px;
  }
  
  .button-container-addpostermodal {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .submit-button-addpostermodal,
  .cancel-button-addpostermodal {
    background-color: #0183ff;
    color: white;
    padding: 10px 25px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 1rem;
    width: 120px; /* Ensuring both buttons are of equal width */
    transition: background-color 0.3s ease;
  }
  
  .cancel-button-addpostermodal {
    background-color: #ffffff;
    color: #007bff;
    border: 1px solid #007bff;
    cursor: pointer; 
     
}
  
  .submit-button-addpostermodal:hover {
    background-color: #0056b3;
  }
  
  .cancel-button-addpostermodal:hover {
    background-color: #e6f7ff;
  }
  
 