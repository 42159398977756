/* Login.css */

.login-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f0f2f5;
}

.logo-container {
  margin-bottom: 20px;
}

.logo {
  width: 100px;
  height: auto;
}

.login-container {
  width: 350px;
  background: #ffffff;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.form {
  display: flex;
  flex-direction: column;
}

.form h2 {
  text-align: center;
  margin-bottom: 15px;
}

.form-description {
  text-align: center;
  font-size: 0.9rem;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input[type="text"],
.form-group input[type="email"],
.form-group input[type="password"],
.form-group select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box; /* include padding and border in element's width */
}

.password-input-container {
  position: relative;
}

.password-input-container input {
  width: calc(100% - 30px);
  padding-right: 30px; /* make space for visibility icon */
}

.toggle-password-visibility {
  position: absolute;
  right: 5px;
  top: 8px;
  background: none;
  border: none;
  cursor: pointer;
  color: #333;
}

.fas {
  font-size: 1rem;
}

.additional-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.85rem;
  margin-bottom: 20px;
}

.remember-me {
  display: flex;
  align-items: center;
}

.remember-me input[type="checkbox"] {
  margin-right: 5px;
}

.forgot-password {
  color: #0066cc;
  text-decoration: none;
  cursor: pointer;
}

.error {
  color: #d32f2f;
  font-size: 0.8rem;
  margin-top: 5px;
}

button {
  background-color: #007bff;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #0056b3;
}

.footer {
  text-align: center;
  margin-top: 20px;
  font-size: 0.8rem;
  color: #666;
}
