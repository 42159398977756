.signin-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: #f0f2f5;
  position: relative;
}

.logo-container {
  margin-bottom: 20px;
}

.logo {
  height: 100px;
}

.signin-container {
  background: #fff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1;
}

.form {
  display: flex;
  flex-direction: column;
}

.form-description {
  margin-bottom: 20px;
  color: #666;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  margin-bottom: 0px;
  font-weight: bold;
}

.password-input-container {
  display: flex;
  align-items: center;
}

.password-input-container input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.toggle-password-visibility {
  background: none;
  border: none;
  margin-left: -30px;
  cursor: pointer;
  color: #666;
}

.button-group {
  display: flex;
  justify-content: space-between;
}

.submit-btn,
.cancel-btn {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submit-btn {
  background-color: #007bff;
  color: white;
}

.cancel-btn {
  background-color: #6c757d;
  color: white;
}

.footer {
  margin-top: 20px;
  text-align: center;
}
.signin-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: #f0f2f5;
  position: relative;
}

.logo-container {
  margin-bottom: 20px;
}

.logo {
  height: 65px;
  width: 70px;
}

.signin-container {
  background: #fff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1;
}

.form {
  display: flex;
  flex-direction: column;
}

.form-description {
  margin-bottom: 20px;
  color: #666;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  margin-bottom: 5px;
  font-weight: bold;
}

.password-input-container {
  display: flex;
  align-items: center;
}

.password-input-container input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.toggle-password-visibility {
  background: none;
  border: none;
  margin-left: -30px;
  cursor: pointer;
  color: #666;
}

.password-requirements {
  margin-bottom: 20px;
  color: #6b6a6a; /* Red color to highlight the requirements */
  font-size: 11px;
}

.button-group {
  display: flex;
  justify-content: space-between;
}

.submit-btn,
.cancel-btn {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submit-btn {
  background-color: #007bff;
  color: white;
}

.cancel-btn {
  background-color: #6c757d;
  color: white;
}

.footer {
  margin-top: 30px;
  text-align: center;
}
