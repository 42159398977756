

.dashboard-homepage {
  display: flex;
  height: 100vh;
  font-family: Arial, sans-serif;
}

.sidebar-homepage {
  position: fixed; /* Make the sidebar fixed */
  top: 0;
  left: 0;
  height: 100vh; /* Full viewport height */
  z-index: 1000;
  width: 250px;
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  border-top: 1px solid #eee;
}

.logo-container-homepage {
  margin-bottom: 20px;
  width: 100px;
  height: 100px;
  margin-left: 70px;
  margin-top: 16px;
}

.logo-homepage {
  margin-bottom: 20px;
}

.logo-homepage img {
  width: 100%;
}

.nav-container-homepage {
  flex-grow: 1;
}

.nav-homepage ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.nav-homepage ul li {
  padding: 15px 0;
  cursor: pointer;
  font-size: 16px;
  color: #6d6d6d;
  font-weight: 600;
}

.nav-homepage ul li a {
  text-decoration: none;
  color: inherit;
  display: flex;
  align-items: center;
  width: 100%;
}

.nav-homepage ul li.active,
.nav-homepage ul li:hover {
  background-color: #f5faff;
  color: #0183ff;
}

.nav-homepage ul li .nav-icon-homepage {
  margin-right: 10px;
}

.main-content-homepage {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: #f9f9f9;
  padding: 20px;
  margin-left: 250px;
  margin-top: 70px;
  overflow-y: scroll; /* For scroller Firefox */
  scrollbar-width: thin; /* Reduce the width of the scrollbar */
  /* scrollbar-color: #007bff #fff;   */
}

.header-homepage {
  position: fixed;
  top: 0;
  left: 250px;
  right: 0;
  height: 70px;
  z-index: 999;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #fff;
  border-bottom: 1px solid #eee;
  margin-bottom: 20px;
  border-top: 1px solid #eee;
}
.founder-homepage {
  font-size: 24px;
  font-weight: 700;
  font-family: "Inter", sans-serif;
}

.profile-section-homepage {
  display: flex;
  align-items: center;
}

.notification-icon-homepage {
  height: 30px;
  width: 30px;
  font-size: 20px;
  border-radius: 50%;
  border: 1px solid #eaeaea;
  padding: 1px;
  margin-right: 20px;
}

.user-info-homepage {
  display: flex;
  align-items: center;
}

.user-initials-homepage {
  color: #fff;
  padding: 5px;
  border: 1px solid #eaeaea;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  font-size: 18px;
}

.user-details-homepage {
  text-align: left;
}
.logout-button-homepage {
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
  padding: 8px 5px;
  border-radius: 4px;
}

.user-name-homepage {
  font-weight: bold;
  font-size: 14px;
}

.user-email-homepage {
  color: #666;
  font-size: 12px;
}

.content-homepage {
  padding: 1px;
}

.dashboard-cards-homepage {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.card-homepage {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 20%;
}

.card-homepage h3 {
  margin: 0;
  font-size: 18px;
  color: #6d6d6d;
}

.card-ohorts-count-link-to-cohorts-page-homepage {
  color: #007bff;
  cursor: pointer;
  text-decoration: none;
  margin-top: 10px;
  font-family: "Inter", sans-serif;
}
.cohorts-heading-tbl-homepage {
  color: #4a4a4a;
  font-size: 18px;
  font-family: "Inter", sans-serif;
}
.program-table-body-homepage {
  color: #4a4a4a;
  font-size: 15px;
  font-family: "Inter", sans-serif;
}
.card-ohorts-count-link-to-cohorts-page-homepage:hover {
  color: #000000;
  cursor: pointer;
}

.card-number-homepage {
  margin-top: 10px;
  font-size: 32px;
  color: #0183ff;
}

.table-container-homepage {
  background: #fff;
  padding: 20px;
}

.program-table-homepage {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.program-table-homepage th,
.program-table-homepage td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #eee;
}

.program-table-homepage th {
  background-color: #f5faff;
  color: #0183ff;
}

.program-table-homepage {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  border-radius: 10px;
  overflow: hidden;
}

.program-table-homepage th {
  background-color: #f5faff;
  color: #0183ff;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 1px;
  padding: 12px;
}

.program-table-homepage th:first-child,
.program-table-homepage td:first-child {
  text-align: left;
}

.program-table-homepage th:not(:first-child),
.program-table-homepage td:not(:first-child) {
  text-align: center;
}

.program-table-homepage td {
  padding: 15px 12px;
  color: #333;
  background-color: #fff;
  transition: background-color 0.2s ease;
}

.program-table-homepage tr:hover td {
  background-color: #f0f4ff;
}

.program-table-homepage th,
.program-table-homepage td {
  border-bottom: 1px solid #ddd;
}

.program-table-homepage tbody tr:last-child td {
  border-bottom: none;
}

.program-table-homepage tbody tr:nth-child(even) td {
  background-color: #fafafa;
}

.program-table-homepage td strong {
  color: #000;
  font-weight: 700;
}

.dashboard-cards-homepage {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.card-homepage {
  flex: 1;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out,
    background-color 0.2s ease-in-out;
  text-align: center;
}

.card-homepage h4 {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  color: #6d6d6d;
}

.card-homepage .count-homepage {
  font-size: 36px;
  font-weight: bold;
  margin-top: 10px;
  color: #0183ff;
}
.count-homepage-numbers {
  font-size: 20px;
  font-weight: bold;
  margin-top: 10px;
  color: #000000;
}

.total-cohort-count-homepage {
  border-top: 1px solid rgb(225, 225, 225);
}

.total-pipeline-count-homepage {
  border-top: 1px solid rgb(225, 225, 225);
}


