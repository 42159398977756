.modal-overlay-addnewformmodal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); 
  display: flex;
  justify-content: center; 
  align-items: center;
  z-index: 1000;
}

.modal-content-addnewformmodal { 
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  width: 400px;   
  max-width: 90%;
  animation: fadeInCustomeditformmodal 0.5s ease-out;
}

.modal-header-addnewformmodal { 
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.close-button-addnewformmodal {
  background: none;
  border: none; 
  font-size: 1.5rem;
  cursor: pointer;
  color: rgb(182, 182, 182);
}
.close-button-addnewformmodal:hover {    
 background-color: #ffffff;
 color: #000000;
}
 

.modal-form-addnewformmodal .form-group-addnewformmodal {
  margin-bottom: 15px;
}

.modal-form-addnewformmodal .form-group-addnewformmodal label {
  display: block;
  margin-bottom: 5px;
  font-weight: 600;
  font-size: 14px;

}

.modal-form-addnewformmodal .form-group-addnewformmodal input,
.modal-form-addnewformmodal .form-group-addnewformmodal select {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.status-options-addnewformmodal label {
  display: block;
  margin-top: 0px;
}

.modal-footer-addnewformmodal {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.submit-button-addnewformmodal {
  background-color: #007bff;
  color: white; 
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
} 

.submit-button-addnewformmodal:hover {
  background-color: #0056b3;
}

.cancel-button-addnewformmodal {   
  background-color: #ffffff;
  color: #007bff;
  border: 1px solid #007bff;
  padding: 10px 69px;
  cursor: pointer;
  border-radius: 5px;
} 

.cancel-button-addnewformmodal:hover {
 background-color: #e6f7ff;
}


@keyframes fadeInCustomeditformmodal {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
} 


