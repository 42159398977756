.dashboard-homepage-pipeline {
  display: flex;
  height: 100vh;
  font-family: Arial, sans-serif;
}

.sidebar-homepage-pipeline {
  width: 250px;
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  position: fixed; /* Fix the sidebar */
  height: 100vh; /* Full height */
  top: 0;
  left: 0;
  overflow: hidden; /* Prevent scrolling within the sidebar */
  z-index: 100; /* Ensure it's on top of other elements */
  border-top: 1px solid #eee;
}

.logo-container-homepage-pipeline {
  margin-bottom: 20px;
  width: 100px;
  height: 100px;
  margin-left: 70px;
  margin-top: 16px;
}

.logo-homepage-pipeline img {
  width: 100%;
}

.nav-container-homepage-pipeline {
  flex-grow: 1;
}

.nav-homepage-pipeline ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.nav-homepage-pipeline ul li {
  padding: 15px 0;
  cursor: pointer;
  font-size: 16px;
  color: #6d6d6d;
  font-weight: 600;
}

.nav-homepage-pipeline ul li a {
  text-decoration: none;
  color: inherit;
  display: flex;
  align-items: center;
  width: 100%;
}

.nav-homepage-pipeline ul li.active,
.nav-homepage-pipeline ul li:hover {
  background-color: #f5faff;
  color: #0183ff;
}

.nav-homepage-pipeline ul li .nav-icon-homepage-pipeline {
  margin-right: 10px;
}

.main-content-homepage-pipeline {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: #f9f9f9;
  padding: 20px;
  margin-left: 250px; /* Offset for fixed sidebar */
  margin-top: 70px; /* Offset for fixed header */
  overflow-y: auto; /* Make the content scrollable */
scrollbar-width: thin; /* Narrow scrollbar for Firefox */
}

.header-homepage-pipeline {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #fff;
  border-bottom: 1px solid #eee;
  margin-bottom: 20px;
  position: fixed; /* Fix the header */
  top: 0;
  left: 250px; /* Align with the fixed sidebar */ 
  width: calc(100% - 250px); /* Adjust width to account for sidebar */
  z-index: 100; /* Ensure it stays on top */
  border-top: 1px solid #eee;
  border: 1px solid #eee;
height: 70px;
}

.founder-homepage-pipeline {
  font-size: 24px;
  font-weight: 700;
}

.profile-section-homepage-pipeline {
  display: flex;
  align-items: center;
}

.user-info-homepage-pipeline {
  display: flex;
  align-items: center;
}

.user-initials-homepage-pipeline {
  color: #fff;
  padding: 5px;
  border: 1px solid #eaeaea;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  font-size: 18px;
}

.user-details-homepage-pipeline {
  text-align: left;
}

.logout-button-homepage-pipeline {
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
  padding: 8px 5px;
  border-radius: 4px;
  }

.user-name-homepage-pipeline {
  font-weight: bold;
  font-size: 14px;
}

.user-email-homepage-pipeline {
  color: #666;
  font-size: 12px;
}

.content-homepage-pipeline {
  padding: 1px;
}

.pipelines-header-pipeline {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pipelines-list-pipeline {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  justify-content: flex-start; /* Align cards to the left */
  gap: 10px; /* Add gap between cards */
}
/*** START CHANGE FOR responsive card ---***/
.pipeline-card-pipeline {
  background-color: #fff;
  border: 1px solid #eaeaea;
   box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
  text-align: center;
  word-wrap: break-word;  
  overflow: hidden;
  text-overflow: ellipsis;  
  padding: 10px;
  margin: 10px;
   flex-grow: 1;
  min-width: 300px; /* Minimum width for cards */
  flex-basis: calc(32% - 20px); /* 3 cards per row */
  max-width: calc(32% - 10px); /* For large screens, maintain 3 cards */
}

.pipeline-card-pipeline:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  transform: translateY(-5px);
  background-color: #f1f8ff;
}


@media (max-width: 1200px) {
  .pipeline-card-pipeline {
    flex-basis: calc(33.33% - 20px); /* 3 cards per row */
  }
}

@media (max-width: 768px) {
  .pipeline-card-pipeline {
    flex-basis: calc(50% - 20px); /* 2 cards per row */
  }
}

@media (max-width: 480px) {
  .pipeline-card-pipeline {
    flex-basis: calc(100% - 20px); /* 1 card per row */
  }
}

/*** END CHANGE FOR responsive card ---***/
.count-pipeline {
  display: flex;
  justify-content:center;
  color: #007bff;
  cursor:pointer;
  text-decoration: none;

  margin-top: 10px;
}
.count-pipeline:hover {
  display: flex;
  justify-content:center;
  color: #000000;
  margin-top: 10px;
  cursor:pointer;
}

.pipeline-name-pipeline {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
}
.pipeline-stats-pipeline p {
  font-size: 14px;
  margin-bottom: 5px;
}
.pipeline-card-actions-pipeline {
  display: flex;
  justify-content:center;
  gap: 20px;
  margin-top: 15px;
}

.view-edit-pipeline { 
  background-color: #ffffff;
  color: #007bff;
  border: 1px solid #007bff;
  padding: 4px 5px;
  cursor: pointer;
  border-radius: 4px; 
  transition: background-color 0.3s ease;
  /* margin: 5px; */
}
 
.delete-pipeline-pm {
  background-color: #ffffff;
  border: 1px solid #f83d3d;
  color: #f83d3d; 
  padding: 4px 5px;
  cursor: pointer;
  border-radius: 4px; 
  transition: background-color 0.3s ease;
  /* margin: 5px; */
}

.view-edit-pipeline:hover {
  background-color: #e6f7ff;
}

.delete-pipeline-pm:hover {
  background-color: #f7e9e9;
}

.add-new-button-pipeline {
  color: white;
  background-color: #0183ff;
  border: 1px solid #0183ff;
  border-radius: 5px;
  padding: 5px 7px;
  cursor: pointer;
  font-size: 16px;
}

.add-new-button-pipeline:hover {
  color: #007bff;
  background-color: #fff;
  border: 1px solid #0183ff;
  border-radius: 5px;
  padding: 5px 7px;
  cursor: pointer;
  font-size: 16px;
}

.custom-ui-pipeline {
padding: 30px;
border-radius: 8px;
background: white;
text-align: center;
box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
max-width: 400px;
}

.custom-ui-pipeline h1 {
font-size: 24px;
margin-bottom: 10px;
}

.custom-ui-pipeline p {
font-size: 16px;
margin-bottom: 20px;
}

.button-group-pipeline {
display: flex;
justify-content: center;
gap: 20px;
}

.delete-button-pipeline {
background-color: #ff7878;
color: #fff;
padding: 8px 16px;
border: none;
border-radius: 4px;
cursor: pointer;
}

.delete-button-pipeline:hover {
background-color: #ff0000;
}

.cancel-button-normal-pipeline {
background-color: #ffffff;
color: #007bff;
border: 1px solid #007bff;
padding: 8px 16px;
border-radius: 4px;
cursor: pointer;
}

.cancel-button-normal-pipeline:hover {
background-color: #e6f7ff;
}

.custom-overlay-pipeline {
background: rgba(90, 90, 90, 0.5);
}
/*** START CHANGE FOR full background--- ***/
.custom-overlay-pipeline {
  position: fixed; /* Fix the overlay to the viewport */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw; /* Ensure it covers the full width */
  height: 100vh; /* Ensure it covers the full height */
   z-index: 9999; /* High z-index to be above everything */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Prevent scrolling */
}