.modal-overlay-addprogrammanager {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content-addprogrammanager {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  max-width: 80%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
}

.modal-title-addprogrammanager {
  text-align: center;
  margin-bottom: 20px;
}

.form-group-addprogrammanager {
  margin-bottom: 15px;
}

.form-group-addprogrammanager label {
  display: block;
  margin-bottom: 5px;
  font-weight: 600;
  font-size: 14px;
}

.form-group-addprogrammanager input {
  width: 100%;
  padding: 8px;
  border-radius: 3px;
  border: solid 1px rgb(179, 178, 178);
}

.button-group-addprogrammanager {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.btn-primary-addprogrammanager {
  padding: 10px 20px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

.btn-secondary-addprogrammanager {
  padding: 10px 20px;
  border: none;
  background-color: #6c757d;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 10px; /* Add margin between buttons */
}

.btn-primary-addprogrammanager:hover {
  background-color: #0056b3;
}

.btn-secondary-addprogrammanager:hover {
  background-color: #5a6268;
}

.error-addprogrammanager {
  color: red;
  font-size: 0.9em;
}

button[type="submit"],
.submit-btn-addprogrammanager {
  width: 48%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 13px;
  box-sizing: border-box;
}

.btn-secondary22-addprogrammanager {
  padding: 10px 68px;
  border: solid 1px #007bff;
  background-color: white;
  color: #007bff;
}

.btn-secondary22-addprogrammanager:hover {
  background-color: #d5e7fc;
}
