/* AddOrganizationModal.css */
.modal-overlay-orgmodal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content-orgmodal {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 26%;
  max-width: 500px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.modal-title-orgmodal {
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}

.form-group-orgmodal {
  margin-bottom: 15px;
}

.form-group-orgmodal label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group-orgmodal input {
  width: calc(100% - 20px);
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 13px;
}

.button-container-orgmodal {
  text-align: center;
  margin-top: 20px;
}

.btn-primary-orgmodal {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.btn-primary-orgmodal:hover {
  background-color: #0056b3;
}

.btn-secondary-orgmodal-cancel {
  margin-left: 10px;
  margin-right: 15px;
  width: 43%;
  background-color: #ffffff;
  color: #007bff;
  border: 1px solid #007bff;
  cursor: pointer;
  border-radius: 5px;
}

.btn-secondary-orgmodal-cancel:hover{
  background-color: #e6f7ff;
}
