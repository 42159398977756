

/* Modal overlay styling */
.modal-overlay-EditOrganizationModal-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Modal content styling */
.modal-content-EditOrganizationModal-modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 27%;
  max-width: 500px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
}

/* Modal title styling */
.modal-title {
  margin-bottom: 20px;
  font-size: 24px;
  text-align: center;
}

/* Form group styling */
.form-group-EditOrganizationModal-form-group {
  margin-bottom: 15px;
}

/* Label styling */
.form-group-EditOrganizationModal-form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  text-align: left;
}

/* Input field styling */
.form-group-EditOrganizationModal-form-group input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Button group styling */
.EditOrganizationModal-button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

/* Primary button styling */
.btn-primary-EditOrganizationModal-btn-primary {
  background-color: #1c88f8;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 48%;
}

.btn-primary-EditOrganizationModal-btn-primary:hover {
  background-color: #007bff;
}

/* Secondary button styling */
.btn-secondary-EditOrganizationModal-cancel {
  margin-left: 0px;
  margin-right: 0;
  width: 48%;
  background-color: #ffffff;
  color: #007bff;
  border: 1px solid #007bff;
  cursor: pointer;
  border-radius: 5px;
}

.btn-secondary-EditOrganizationModal-cancel:hover {
  background-color: #e6f7ff;
}

/* Responsive styling for smaller screens */
@media (max-width: 600px) {
  .modal-content-EditOrganizationModal-modal-content {
    padding: 10px;
    width: 95%;
  }
}
