/* .dashboard-homepage-vieweditpipeline {
    display: flex;
    height: 100vh;
    font-family: Arial, sans-serif;
  } */
.dashboard-homepage-vieweditpipeline {
  display: flex;
  height: 100vh;
  font-family: Arial, sans-serif;
  overflow: hidden; /* Prevent overall page scrolling */
}

/* .sidebar-homepage-vieweditpipeline {
    width: 250px;
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
  } */
/** START CHANGE FOR FIXED SIDEBAR **/
.sidebar-homepage-vieweditpipeline {
  width: 250px;
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
  display: flex;
  flex-direction: column;
  position: fixed; /* Fixed position */
  height: 100vh; /* Full height */
  top: 0;
  left: 0;
  overflow: hidden; /* Prevent scrolling inside the sidebar */
  z-index: 100;
}
/** END CHANGE FOR FIXED SIDEBAR **/
.logo-container-homepage-vieweditpipeline {
  margin-bottom: 20px;
  width: 100px;
  height: 100px;
  margin-left: 70px;
  margin-top: 16px;
}

.dristilogo-homepage-vieweditpipeline {
  width: 100%;
}

.nav-container-homepage-vieweditpipeline {
  flex-grow: 1;
}

.nav-homepage-vieweditpipeline ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.nav-homepage-vieweditpipeline ul li {
  padding: 15px 0;
  cursor: pointer;
  font-size: 16px;
  color: #6d6d6d;
  font-weight: 600;
}

.nav-homepage-vieweditpipeline ul li a {
  text-decoration: none;
  color: inherit;
  display: flex;
  align-items: center;
  width: 100%;
}

.nav-homepage-vieweditpipeline ul li.active,
.nav-homepage-vieweditpipeline ul li:hover {
  background-color: #f5faff;
  color: #0183ff;
}

.nav-homepage-vieweditpipeline ul li .nav-homepage-vieweditpipeline {
  margin-right: 10px;
}

/* .main-content-homepage-vieweditpipeline {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    background-color: #f9f9f9;
    padding: 20px;
  } */
.main-content-homepage-vieweditpipeline {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: #f9f9f9;
  padding: 20px;
  margin-left: 250px;
  overflow-y: auto;
  margin-top: 80px;
    overflow-y: scroll;  /* For scroller Firefox */
   scrollbar-width: thin; /* Reduce the width of the scrollbar */
  /* scrollbar-color: #007bff #fff;   */
}

/* .header-homepage-vieweditpipeline {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #fff;
    border-bottom: 1px solid #eee;
    margin-bottom: 20px;
  } */
/** START CHANGE FOR FIXED HEADER **/
.header-homepage-vieweditpipeline {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #fff;
  border-bottom: 1px solid #eee;
  margin-bottom: 20px;
  position: fixed; /* Fixed position */
  top: 0;
  left: 250px; /* Offset for the fixed sidebar */
  width: calc(100% - 250px); /* Adjust width to account for the sidebar */
  z-index: 100; /* Ensure the header appears above the content */
  margin-left: 1px;
}
/** END CHANGE FOR FIXED HEADER **/

.founder-homepage-vieweditpipeline {
  font-size: 24px;
  font-weight: 700;
}

.profile-section-homepage-vieweditpipeline {
  display: flex;
  align-items: center;
}

.user-info-homepage-vieweditpipeline {
  display: flex;
  align-items: center;
}

.user-initials-homepage-vieweditpipeline {
  color: #fff;
  padding: 5px;
  border: 1px solid #eaeaea;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  font-size: 18px;
}

.user-details-homepage-vieweditpipeline {
  text-align: left;
}

.user-name-homepage-vieweditpipeline {
  font-weight: bold;
  font-size: 14px;
}

.user-email-homepage-vieweditpipeline {
  color: #666;
  font-size: 12px;
}

.logout-button-homepage-vieweditpipeline {
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
  padding: 8px 5px;
  border-radius: 4px;
}

.pipeline-header-vieweditpipeline {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.pipeline-details-vieweditpipeline {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}
.back-button-vieweditpipeline {
  color: white;
  background-color: #0183ff;
  border: 1px solid #0183ff;
  border-radius: 5px;
  padding: 5px 7px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.back-button-vieweditpipeline:hover {
  color: #007bff;
  background-color: #fff;
  border: 1px solid #0183ff;
  border-radius: 5px;
  padding: 5px 7px;
  cursor: pointer;
  font-size: 16px;
}

.save-button-vieweditpipeline {
  background-color: #28a745;
  color: white;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

.save-button-vieweditpipeline:hover {
  background-color: #218838;
}

.general-tab-input-heading-vieweditpipeline {
  font-weight: 550;
  color: #4d4d4d;
}

.rounds-container {
  display: flex;
  gap: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.round-card {
  background-color: #e9f6ff;
  border: 1px solid #dce4ec;
  border-radius: 8px;
  padding: 15px;
  width: 330px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  position: relative;
}
.round-card-heading {
  font-size: medium;
  font-weight: 550;
}

/* Styling for the "+" button */
.add-round-button-vieweditpipeline {
  background-color: #51a5ff;
  color: white;
  padding: 2px; /* Reduce padding for smaller button */
  border: none;
  border-radius: 50%;
  font-size: 16px; /* Reduce font size */
  width: 22px; /* Reduce button size */
  height: 22px;
  text-align: center;
  cursor: pointer;
  position: absolute;
  bottom: -12px; /* Adjust the position if necessary */
  left: 50%;
  transform: translateX(-50%);
  transition: background-color 0.3s ease, transform 0.2s ease; /* Add transition for hover effect */
}

/* Add hover effect for the "+" button */
.add-round-button-vieweditpipeline:hover {
  background-color: #8f8e8e; /* Change color on hover */
  transform: translateX(-50%) scale(1.1);
  color: white;
}

.pipeline-tabs-vieweditpipeline {
  display: flex;
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px;
}

.tab-button-vieweditpipeline {
  background: none;
  border: none;
  font-size: 16px;
  padding: 10px 20px;
  cursor: pointer;
  color: #6d6d6d;
  position: relative;
}

.tab-button-vieweditpipeline.active {
  color: #007bff;
  font-weight: bold;
}

.tab-button-vieweditpipeline.active::after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background-color: #007bff;
  position: absolute;
  bottom: -1px;
  left: 0;
}

.tab-button-vieweditpipeline:hover {
  color: #000000;
  background-color: #d7ecff;
  font-weight: bold;
}

.tab-content {
  padding: 20px 0;
}
/** START CHANGE FOR SWITCH BUTTON POSITIONING **/

.general-option-vieweditpipeline {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.switch-vieweditpipeline {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
}

.switch-vieweditpipeline input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider-vieweditpipeline {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider-vieweditpipeline:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider-vieweditpipeline {
  background-color: #2196f3;
}

input:checked + .slider-vieweditpipeline:before {
  transform: translateX(14px);
}
/*** START CHANGE FOR show last date to apply styling --- ***/
.last-date-to-apply-vieweditpipeline {
  margin-top: 10px;
  font-size: 14px;
  color: #333;
  font-weight: 500;
}
/*** END CHANGE FOR show last date to apply styling --- ***/
/* Position the toggle switch and heading on the same line for 'Show Last Date to Apply?' */
.toggle-last-date-to-apply-vieweditpipeline {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

/** END CHANGE FOR SWITCH BUTTON POSITIONING **/
/** START CHANGE FOR SWITCH AND CHANGE BUTTON ALIGNMENT **/
.application-option-vieweditpipeline {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.switch-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

/** Align the form template text and Change button **/
.form-template-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.form-template-text {
  flex: 1;
}

.change-button-container {
  display: flex;
  justify-content: flex-end;
}

.change-button-vieweditpipeline {
  background-color: #007bff;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  border: none;
  font-size: 14px;
}

.change-button-vieweditpipeline:hover {
  background-color: #0056b3;
}
/** END CHANGE FOR SWITCH AND CHANGE BUTTON ALIGNMENT **/
/** START CHANGE FOR FORM FIELD STYLING WITH applicationformdesign POSTFIX **/
.application-form-design-applicationformdesign {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-field-applicationformdesign {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.form-field-applicationformdesign label {
  font-weight: bold;
}

.form-field-applicationformdesign input[type="text"],
.form-field-applicationformdesign textarea {
  flex: 1;
  margin-left: 20px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}
.form-field-applicationformdesigndescription {
  align-items: center;
  justify-content: space-between;
}

.form-field-applicationformdesigndescription label {
  font-weight: bold;
}

.form-field-applicationformdesigndescription input[type="text"],
.form-field-applicationformdesigndescription textarea {
  flex: 1;
  margin-left: 20px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}
.title-display-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
}

.title-display-container span {
  font-size: 16px;
  font-weight: normal;
  margin-right: 50px;
}

.edit-button-applicationformdesign {
  background-color: #007bff;
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  border: none;
  font-size: 14px;
}

.edit-button-applicationformdesign:hover {
  background-color: #0056b3;
}
.add-button-applicationformdesign {
  background-color: #007bff;
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  border: none;
  font-size: 14px;
}

.add-button-applicationformdesign:hover {
  background-color: #0056b3;
}

.application-form-design-empty-applicationformdesign {
  text-align: center;
  color: #555;
  padding: 40px;
  background-color: #f9f9f9;
  border-radius: 8px;
  border: 1px solid #ddd;
}
/* START CHANGE FOR MOVING POSTER BELOW LABEL AND ADDING POSTFIX */
.poster-container-applicationformdesignposter {
  flex-direction: column;
  margin-top: 10px;
}

.poster-link-applicationformdesignposter {
  color: #007bff;
  text-decoration: none;
  margin-bottom: 10px;
  margin-right: 50px;
}

.poster-link-applicationformdesignposter:hover {
  text-decoration: none;
  color: #000000;
}

.edit-button-applicationformdesignposter {
  background-color: #007bff;
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  border: none;
  font-size: 14px;
}

.edit-button-applicationformdesignposter:hover {
  background-color: #0056b3;
}

.add-button-applicationformdesignposter {
  background-color: #007bff;
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  border: none;
  font-size: 14px;
}

.add-button-applicationformdesignposter:hover {
  background-color: #0056b3;
}
.application-poster-image-vieweditpipeline {
  width: 200px; /* Set the desired width */
  height: 100px; /* Set the desired height */
  /* display: block; */
  margin: 0 auto;
}

/* START CHANGE FOR DESCRIPTION */
.description-container-applicationdesigndescription {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.description-text-container-applicationdesigndescription {
  flex: 1;
  margin-right: 20px;
}

.description-button-container-applicationdesigndescription {
  flex-shrink: 0; /* Ensures the button container doesn't shrink */
}

.edit-button-applicationformdesign-applicationformdesigndescription,
.add-button-applicationformdesign-applicationformdesigndescription {
  background-color: #007bff;
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  border: none;
  font-size: 14px;
}

.edit-button-applicationformdesign-applicationformdesigndescription:hover,
.add-button-applicationformdesign-applicationformdesigndescription:hover {
  background-color: #0056b3;
}

/* //////////supporting-document */

.supporting-documents-form-field-applicationformdesign {
  display: flex;
  flex-direction: column; /* Ensure that elements are stacked vertically */
  align-items: flex-start; /* Align the content to the left, not center */
}
.supporting-documents-list-applicationformsupportingdocuments {
  width: 100%; /* Make sure the list takes up the full width */
  margin-top: 10px;
}

.pareagraph-no-document-applicationformsupportingdocuments {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: small;
}

.supporting-document-item-applicationformsupportingdocuments {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.document-number-applicationformsupportingdocuments {
  font-size: 14px;
  font-weight: bold;
  margin-right: 5px;
}

.document-name-applicationformsupportingdocuments {
  font-size: 14px;
  color: #007bff;
  text-decoration: none;
}

.document-name-applicationformsupportingdocuments:hover {
  text-decoration: none;
  color: #000000;
}
.delete-button-applicationformsupportingdocuments {
  background-color: transparent;
  border: none;
  color: #ff4444;
  cursor: pointer;
}

.delete-button-applicationformsupportingdocuments:hover {
  color: #040404;
  background-color: #ffd4d4;
}

.add-button-applicationformsupportingdocuments {
  background-color: transparent;
  color: rgb(0, 64, 255);
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  border: none;
  font-size: 14px;
  margin-top: 2px;
  font-size: large;
}

.add-button-applicationformsupportingdocuments:hover {
  color: #000000;
  background-color: #d7eaff;
}
/** END CHANGE FOR NUMBERING LOGIC **/

/* General Styling for ViewEditPipeline */
.date-label-vieweditpipeline {
  display: flex;
  align-items: center;
  margin: 2px 0;
  font-family: "Arial", sans-serif;
  font-size: 16px;
  color: #333;
}

.date-picker-vieweditpipeline {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  margin-left: 10px;
  width: 100%;
  /* max-width: 200px; */
  max-width: 155px;
  cursor: pointer;
  border-radius: 5px;
  color: #007bff;
}

.date-picker-vieweditpipeline:hover {
  color: #000000; /* Black color on hover */
}
/** START CHANGE FOR "styling and responsive link" --- **/
.round-link-vieweditpipeline {
  color: #007bff; /* Default color */
  text-decoration: none; /* Remove underline */
  word-break: break-all; /* Ensure the link is responsive */
  margin-left: 20px;
}

.round-link-vieweditpipeline:hover {
  color: #000000; /* Black color on hover */
  text-decoration: none; /* Maintain no underline on hover */
}

.delete-round-button-vieweditpipeline {
  background-color: #ff9595;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  margin-top: 10px; /* Adjust position as needed */
  transition: background-color 0.3s ease;
}

.delete-round-button-vieweditpipeline:hover {
  background-color: #f94040;
  color: #ffffff;
}

.custom-ui-vieweditpipeline {
  position: relative;
  z-index: 1001; /* Higher than the overlay */
  padding: 30px;
  border-radius: 8px;
  background: white;
  text-align: center;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  max-width: 400px;
}


.custom-ui-vieweditpipeline h1 {
  font-size: 24px;
  margin-bottom: 10px;
}

.custom-ui-vieweditpipeline p {
  font-size: 16px;
  margin-bottom: 20px;
}

.button-group-vieweditpipeline {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.delete-button-vieweditpipeline {
  background-color: #ff7878;
  color: #fff;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.delete-button-vieweditpipeline:hover {
  background-color: #ff0000;
}

.cancel-button-normal-vieweditpipeline {
  background-color: #ffffff;
  color: #007bff;
  border: 1px solid #007bff;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.cancel-button-normal-vieweditpipeline:hover {
  background-color: #e6f7ff;
}

.custom-overlay-vieweditpipeline {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(90, 90, 90, 0.5);
  z-index: 1000; /* Ensure it covers other elements */
}


