/* Verified.css */

body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
}

.email-verification-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 3; /* Allows the container to grow and push the footer down */
  background-color: #f9f9f9;
  padding: 20px;
}

.logo {
  width: 70px;
  height: 70px;
  margin-bottom: 20px;
}

.verification-form {
  background-color: #fff;
  padding: 20px 30px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 360px;
  text-align: center;
  
  margin-bottom: 20px;
}

.head2 {
  margin-bottom: 20px;
  color: #333;
  text-align: left;
  font-weight: 700;
  font-size: 21px;
}

/* OTP container styles */
.otp-container {
  display: flex;
  justify-content: space-between;
  margin: 15px 0;
}

.otp-container input {
  font-size: 24px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.otp-container input:focus {
  border-color: #007bff;
  outline: none;
}

.resend-text {
  color: #8a8a8a;
  margin-top: 10px;
  text-align: left;
  font-size: 13px;
}

.resend-text button {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  font-size: 14px;
  text-decoration: underline;
}

.resend-text button:hover {
  color: #0056b3;
}

.button-container {
  margin-top: 20px;
}

/* Button styles */
.wide-button {
  width: 48%;
  height: 40px;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: 1px solid #007bff;
  border-radius: 4px;
  cursor: pointer;
  font-size: 15px;
  margin-bottom: 10px;
}

.wide-button:hover {
  background-color: #0056b3;
}

/* Start Over button */
.wide-button2 {
  width: 48%;
  height: 40px;
  padding: 10px;
  color: #535455;
  background-color: rgb(255, 255, 255);
  color: #0056b3;
  border: 1px solid #0056b3;
  border-radius: 4px;
  cursor: pointer;
  font-size: 15px;
  margin-bottom: 10px;
}

.wide-button2:hover {
  background-color: #58595a;
  border: 1px solid #535455;
  color: #272727;
}

.footer {
  margin-top: 160px;
  font-size: 11px;
  color: #666;
  background-color: none;

}