.signup-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative; /* Ensure relative positioning for child absolute/fixed elements */
}

.logo-container {
  margin-bottom: 20px;
}

.logo {
  width: 8%;
  height: 65px;
  position: absolute;
  top: 20px;
  left: 20px;
}

.signup-container {
  width: 24%;
  max-width: 400px;
  background-color: rgb(255, 255, 255);
  padding: 20px 35px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 35px;
  margin-top: 85px;
  z-index: 1; /* Ensure it is below the modal */
}

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
}

h2 {
  text-align: left;
  margin-bottom: 20px;
}

.form-description {
  color: #909090;
  margin-top: -8px;
  margin-bottom: 15px;
}

.form-group {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: 700;
  font-size: 14px;
}

.form-group input,
.form-group .react-tel-input .form-control {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.form-group .react-tel-input .flag-dropdown {
  border-radius: 4px 0 0 4px;
  border: 1px solid #ccc;
  border-right: none;
}

.form-group .react-tel-input .form-control:focus,
.form-group .react-tel-input .flag-dropdown:focus {
  border-color: #007bff;
  outline: none;
}

.form-group .react-tel-input .form-control {
  padding-left: 50px;
}

.password-input-container {
  display: flex;
  align-items: center;
  width: 100%;
}

.password-input-container input {
  flex: 1;
  padding-right: 30px;
}

.toggle-password-visibility {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  color: #9d9c9c;
  font-size: 14px;
}

.toggle-password-visibility:focus {
  outline: none;
}

.toggle-password-visibility:hover {
  color: #9d9c9c;
}

.form-group-row {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  flex-wrap: wrap;
}

.full-width {
  width: 100%;
}

.drishti {
  color: #007BFF;
}

button[type="submit"],
.submit-btn {
  width: 40%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  box-sizing: border-box;
}

button[type="submit"]:hover,
.submit-btn:hover {
  background-color: #0056b3;
}

.footer {
  margin-top: 20px;
  text-align: center;
  font-size: 11px;
  color: #666;
  background-color: #f0f2f5;
}

/* Password toggle icon styles */
.password-input {
  position: relative;
}

.password-input input {
  width: calc(100% - 30px);
  padding-right: 30px;
}

.password-input svg {
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
  cursor: pointer;
}

.password-input-container {
  position: relative;
  width: 100%;
}

.password-input-container input {
  width: 100%;
  padding-right: 30px;
}

.password-input-container .toggle-password-visibility {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
  font-size: 1rem;
}

.formatted-date {
  margin-top: 5px;
  font-size: 12px;
  color: #555;
}

.modal {
  position: fixed; /* Fixed position to cover the entire viewport */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  z-index: 1000; /* High z-index to ensure it is on top */
}

.modal-content {
  background: white;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  z-index: 1001; /* Ensure content is above the modal background */
}

.modal-content h3 {
  margin-bottom: 0px;
}



.modal-content button:hover {
  background-color: #0056b3;
}
