@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1; 
    transform: scale(1);
  }
}
  
.share-modalsharemodal {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    position: relative;
    width: 400px;
    z-index: 1000;
    animation: fadeIn 0.3s ease-out;  
}

.modal-backgroundsharemodal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%; 
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.share-modal-headersharemodal {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.close-icon-buttonsharemodal {
  background: none;
  border: none; 
  font-size: 1.5rem;
  cursor: pointer; 
  color: rgb(182, 182, 182);
}

.close-icon-buttonsharemodal:hover {
  background-color: #ffffff;
  color: #000000;
}
.share-modalsharemodal h2 {
    margin-top: 0;
}

.share-modalsharemodal p { 
    margin-bottom: 15px;
    margin-top: 10px;
}

.link-containersharemodal {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
}

.link-containersharemodal input {
    width: 65%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.textinlinksharemodal {
    text-align: left;
    font-weight: 500;
}

.copy-link-buttonsharemodal {   
    display: flex;
    align-items: center;
    gap: 5px;
    background-color: #0077b5; /* Blue color for copy link button */
    color: white;
    padding: 10px 12px; /* Adjust padding for appropriate size */
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.8em;
} 

.copy-link-buttonsharemodal .link-iconsharemodal {
    font-size: 1em;
}

.social-share-buttonssharemodal {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin: 20px 0; 
}

.social-share-buttonssharemodal a {
    color: inherit;
    text-decoration: none;
}

.social-share-buttonssharemodal a:hover {
    opacity: 0.8;
}

.social-share-buttonssharemodal svg {
    color: #0077b5; /* Default LinkedIn color */
}

.social-share-buttonssharemodal a[href*="wa.me"] svg {
    color: #25D366; /* WhatsApp color */
}

.social-share-buttonssharemodal a[href*="mailto"] svg {
    color: #D44638; /* Email color */
}


 