body {
  font-family: 'Montserrat', sans-serif;
}

.signin-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: #f0f2f5;
  position: relative;
}

.logo-container {
  margin-bottom: 20px;
}

.logo {
  height: 65px;
  width: 70px;
}

.signin-container {
  background: #fff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1;
  width: 24%;
}

.form {
  display: flex;
  flex-direction: column;
}

.form-description {
  margin-bottom: 20px;
  color: #666;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  margin-bottom: 0px;
  font-weight: bold;
}

.password-input-container {
  display: flex;
  align-items: center;
}

.password-input-container input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.toggle-password-visibility {
  background: none;
  border: none;
  margin-left: -30px;
  cursor: pointer;
  color: #666;
}

.button-group {
  display: flex;
  justify-content: space-between;
  gap: 10px; /* Added gap for spacing between buttons */
}

.submit-btn,
.cancel-btn {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submit-btn {
  background-color: #007bff;
  color: white;
}

.cancel-btn {
  background-color: #6c757d;
  color: white;
}

.footer {
  margin-top: 20px;
  text-align: center;
}

/* New CSS for Modal */

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  position: relative;
  width: 20%;
  max-height: 245px;
}

.para {
  font-size: 12px;
}

.icon-container {
  margin-bottom: 20px;
}

.check-icon {
  color: #0183FF;
  font-size: 50px;
}

.modal h3 {
  margin-bottom: 10px;
}

.modal p {
  margin-bottom: 20px;
  color: #666;
}

.signin-btn {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
}
