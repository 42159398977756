.form-builder-container-evaluatorform {
  display: flex;
  height: 100vh;
}

.form-builder-sidebar-evaluatorform {
  width: 250px;
  background-color: #f7f7f7;
  padding: 20px;
  border-right: 1px solid #ddd;
}

.form-builder-sidebar-evaluatorform h3 {
  margin-bottom: 20px;
}

.draggable-item-evaluatorform { 
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ccc; 
  margin-bottom: 10px;
  border-radius: 4px;
  text-align: center;
  cursor: grab;
  position: relative;
}

.draggable-item-evaluatorform:hover {
  background-color: #F5FAFF;
  color: #0183ff;
}

.draggable-item-evaluatorform::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 24px;
  height: 24px;
  background-size: contain;
  display: none;
}

.draggable-item-evaluatorform:hover::after {
  display: block;
}

.form-builder-content-evaluatorform {
  flex-grow: 1;
  padding: 20px;
}

.form-builder-header-evaluatorform {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.form-builder-buttons-evaluatorform {
  display: flex;
  align-items: center;
}

.form-builder-buttons-evaluatorform button {
  margin-left: 10px;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.form-builder-preview-button-evaluatorform {
  background-color: white;
  color: #007bff;
  border: 1px solid #007bff;
}

.form-builder-preview-button-evaluatorform:hover {
  background-color: #e6f7ff;
}

.form-builder-close-button-evaluatorform {
  background-color: white;
  color: #007bff;
  border: 1px solid #007bff;
}

.form-builder-close-button-evaluatorform:hover {
  background-color: #e6f7ff;
}

.drop-area-evaluatorform {
  border: 2px dashed #020eb8;
  padding: 20px;
  border-radius: 10px;
  min-height: 300px;
  background-color: #e8fffc;
  overflow-y: auto; 
  max-height: calc(100vh - 150px);
}

.dropped-element-evaluatorform {
  padding: 15px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.element-header-evaluatorform {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
}

.element-number-evaluatorform {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border: 2px solid #007bff;
  border-radius: 50%;
  margin-right: 10px;
  font-size: 14px;
  font-weight: bold;
  color: #007bff;
}

.element-label-evaluatorform {
  font-weight: bold;
  font-size: 16px;
  margin-left: 10px;
  display: flex;
  align-items: center;
}

.element-actions-evaluatorform {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.icon-evaluatorform {
  width: 30px;
  height: 30px;
  display: flex;
  font-size: 5px;
  justify-content: center;
  align-items: center;
  border: 1px solid #007bff;
  border-radius: 50%; 
  color: #007bff;
  cursor: pointer;
  transition: all 0.3s ease;
  padding: 5px;
  margin-right: 25px;
}

.icon-evaluatorform:hover {
  background-color: #007bff;
  color: #fff;
}

.icon-evaluatorform.delete-evaluatorform {
  border-color: #dc3545;
  color: #dc3545;
}

.icon-evaluatorform.delete-evaluatorform:hover {
  background-color: #dc3545;
  color: #fff;
}

.icon-evaluatorform:not(.delete-evaluatorform):hover {
  background-color: #007bff;
  color: #fff;
}


.delete-button-yes-evaluatorform { 
  background-color: #ff7878;
  color: #fff;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer; 
}
.delete-button-yes-evaluatorform:hover { 
  background-color: #ff0000;
}

/* .cancel-button-no-evaluatorform1 { 
  background-color: #ffffff;
  color: #007bff;
  border: 1px solid #007bff;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.cancel-button-no-evaluatorform1 :hover { 
  background-color: #ffe6e6;
} */


.element-details-evaluatorform {
  margin-top: 10px;
}

.form-group-evaluatorform {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 15px;
}

.form-group-evaluatorform label {
  display: flex;
  align-items: center;
  font-weight: 500;
  margin-bottom: 5px;
  font-size: 14px;
}

.form-group-evaluatorform .label-text-evaluatorform {
  display: flex;
  align-items: center;
}

.form-group-evaluatorform .label-text-evaluatorform span {
  margin-right: 5px;
}

.form-group-evaluatorform input[type="text"],
.form-group-evaluatorform input[type="email"],
.form-group-evaluatorform select,
.form-group-evaluatorform textarea,
.form-group-evaluatorform input[type="number"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 14px;
}

.form-group-evaluatorform textarea {
  height: 100px;
}

.star-rating-evaluatorform {
  font-size: 24px;
}

.custom-ui-evaluatorform {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 100%;
  max-width: 400px;
  text-align: center;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
}

.custom-overlay-evaluatorform {
  background: rgba(90, 90, 90, 0.5);
}

.rating-scale-evaluatorform {
  display: flex;
  gap: 5px;
}

.rating-item-evaluatorform {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 4px;
  color: #fff;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s;
}

.rating-item-evaluatorform:hover {
  background-color: #555;
}

.rating-10-evaluatorform { background-color: #00b300; }
.rating-9-evaluatorform { background-color: #33cc33; }
.rating-8-evaluatorform { background-color: #66ff66; }
.rating-7-evaluatorform { background-color: #99ff99; }
.rating-6-evaluatorform { background-color: #ccffcc; }
.rating-5-evaluatorform { background-color: #ffff66; }
.rating-4-evaluatorform { background-color: #ffcc66; }
.rating-3-evaluatorform { background-color: #ff9966; }
.rating-2-evaluatorform { background-color: #ff6666; }
.rating-1-evaluatorform { background-color: #ff3333; }

.drag-handle-evaluatorform {
  cursor: grab;
  margin-right: 10px;
  color: blue;
  size: 100px;
}

.drag-handle-evaluatorform:hover {
  color: rgb(255, 174, 0);
}

