/* General styles */
body, html {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

.app-container {
  display: flex;
  height: 100vh;
}

/* Sidebar styles */
.sidebar {
  width: 250px;
  background-color: #f8f9fa;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.logo-container {
  margin-bottom: 20px;
}

.logo img {
  width: 100px;
}

.nav {
  width: 100%;
}

.nav ul {
  list-style-type: none;
  padding: 0;
  width: 100%;
}

.nav ul li {
  padding: 10px 20px;
  width: 100%;
  text-align: left;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.nav ul li.active,
.nav ul li:hover {
  background-color: none;
}

.nav-icon {
  margin-right: 10px;
}

/* Content area styles */
.content {
  flex-grow: 1;
  background-color: #f1f1f1;
  display: flex;
  flex-direction: column;
}

.navbar {
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.navbar input {
  width: 300px;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.user-info {
  display: flex;
  align-items: center;
}

.user-initials {
  background-color: #007bff;
  color: #ffffff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

/* Founder details styles */
.founder-details {
  padding: 20px;
}

.founder-details h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.founder-details-content {
  background: #ffffff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.founder-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.founder-initials {
  background-color: #007bff;
  color: #ffffff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
  margin-right: 10px;
}

.founder-name {
  font-size: 20px;
  font-weight: bold;
}

.founder-info-table {
  width: 100%;
  border-collapse: collapse;
}

.founder-info-table td {
  padding: 10px;
  border: 1px solid #e0e0e0;
}

.founder-info-table .label {
  font-weight: bold;
  background-color: #f7f7f7;
}

.founder-info-table a {
  color: #007bff;
  text-decoration: none;
}

.founder-info-table a:hover {
  text-decoration: underline;
}
