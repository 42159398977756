



.modal-background-applicationdescription {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content-applicationdescription {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 500px;
  z-index: 1010;
  animation: fadeInCustomApplicationDescriptionModal 0.5s ease-out;
}

.modal-description-heading-text-applicationdescription {
  padding: 5px;
  margin-bottom: 10px;
}

.modal-buttons-applicationdescription {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.modal-buttons-applicationdescription button {
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.modal-submit-button-applicationdescription {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
}

.modal-submit-button-applicationdescription :hover {
  background-color: #0056b3;
}

.modal-cancel-button-applicationdescription {
  background-color: #ffffff;
  color: #007bff;
  border: 1px solid #007bff;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
}

.modal-cancel-button-applicationdescription:hover {
  background-color: #e6f7ff;
}

@keyframes fadeInCustomApplicationDescriptionModal {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.modal-background-applicationdescription {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content-applicationdescription {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 500px;
  z-index: 1010;
  animation: fadeInCustomApplicationDescriptionModal 0.5s ease-out;
}

.modal-description-heading-text-applicationdescription {
  padding: 5px;
  margin-bottom: 10px;
}

.modal-buttons-applicationdescription {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.modal-buttons-applicationdescription button {
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.modal-submit-button-applicationdescription {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
}

.modal-submit-button-applicationdescription :hover {
  background-color: #0056b3;
}

.modal-cancel-button-applicationdescription {
  background-color: #ffffff;
  color: #007bff;
  border: 1px solid #007bff;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
}

.modal-cancel-button-applicationdescription:hover {
  background-color: #e6f7ff;
}

@keyframes fadeInCustomApplicationDescriptionModal {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

/* Default text alignment */
.ql-editor {
  text-align: initial;
}

/* Indent effect (moves text slightly to the right) */
.ql-indent-1 {
  margin-left: 20px;
}

.ql-indent-2 {
  margin-left: 40px;
}

.ql-indent-3 {
  margin-left: 60px;
}

/* Alignment options (to maintain user selections) */
.ql-align-center {
  text-align: center;
}

.ql-align-right {
  text-align: right;
}

.ql-align-justify {
  text-align: justify;
}

sup {
  font-size: 0.6em;
  vertical-align: super;
  margin-left: 1px; /* Adjust spacing */
}

.ql-editor a {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.ql-editor a:hover {
  color: darkblue;
}







