.ViewOrganizationModal-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.ViewOrganizationModal-modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 30%;
  max-width: 400px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  max-height: fit-content;
}

.ViewOrganizationModal-modal-title {
  margin-bottom: 20px;
  font-size: 18px;
  text-align: center;
}

.ViewOrganizationModal-details {
  margin-bottom: 20px;
}

.ViewOrganizationModal-detail {
  margin-bottom: 10px;
  text-align: left;
}

.ViewOrganizationModal-loading {
  text-align: center;
}

.ViewOrganizationModal-close-btn {
  background-color: #1c88f8;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: auto;
}

.ViewOrganizationModal-close-btn:hover {
  background-color: #007bff;
}
