.dashboard-editcohortsmodal {
  display: flex;
  height: 100vh;
  font-family: Arial, sans-serif;
  overflow: hidden; /* Prevent overall scrolling */
}

/** START CHANGE FOR FIXED SIDEBAR **/
.sidebar-editcohortsmodal {
  width: 250px;
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  position: fixed; /* Fixed position */
  height: 100vh; /* Full height */
  top: 0;
  left: 0;
  overflow: hidden; /* Prevent scrolling */
  z-index: 100;
  border-top: 1px solid #eee;
}
/** END CHANGE FOR FIXED SIDEBAR **/

.logo-container-editcohortsmodal {
  margin-bottom: 20px;
  width: 100px;
  height: 100px;
  margin-left: 70px;
  margin-top: 16px;
}

.logo-editcohortsmodal img {
  width: 100%;
}

.nav-container-editcohortsmodal {
  flex-grow: 1;
}

.nav-editcohortsmodal ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.nav-editcohortsmodal ul li {
  padding: 15px 0;
  cursor: pointer;
  font-size: 16px;
  color: #6d6d6d;
  font-weight: 600;
}

.nav-editcohortsmodal ul li a {
  text-decoration: none;
  color: inherit;
  display: flex;
  align-items: center;
  width: 100%;
}

.nav-editcohortsmodal ul li.active,
.nav-editcohortsmodal ul li:hover {
  background-color: #f5faff;
  color: #0183ff;
}

.nav-editcohortsmodal ul li .nav-icon-editcohortsmodal {
  margin-right: 10px;
}

/** START CHANGE FOR FIXED HEADER **/
.header-editcohortsmodal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #fff;
  border-bottom: 1px solid #eee;
  position: fixed; /* Fixed position */
  top: 0;
  left: 250px; /* To align with the fixed sidebar */
  width: calc(100% - 250px); /* Adjust width */
  z-index: 100;
  margin-left: 1px;
  border-top: 1px solid #eee;
  height: 70px;
}
/** END CHANGE FOR FIXED HEADER **/

.main-content-editcohortsmodal {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: #f9f9f9;
  padding: 20px;
  margin-left: 250px; /* Margin for the fixed sidebar */
  margin-top: 70px; /* Margin for the fixed header */
  overflow-y: auto; /* Enable scrolling for the form content */
  overflow-y: scroll;  /* For scroller Firefox */
  scrollbar-width: thin; /* Reduce the width of the scrollbar */
 /* scrollbar-color: #007bff #fff;   */
}
.top-editcohort-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.editcohort-header {
  font-size: 18px;
  font-weight: 600;
  /* margin-bottom:20px; */
}

.back-button-editcohorts {
  color: white;
  background-color: #0183ff;
  border: 1px solid #0183ff;
  border-radius: 5px;
  padding: 5px 7px;
  cursor: pointer;
  font-size: 16px;
    transition: background-color 0.3s ease; 
  }
  
  .back-button-editcohorts:hover {
  color: #007bff;
  background-color: #fff;
  border: 1px solid #0183ff;
  border-radius: 5px;
  padding: 5px 7px;
  cursor: pointer;
  font-size: 16px;
  }

.founder-editcohortsmodal {
  font-size: 24px;
  font-weight: 700;
}

.profile-section-editcohortsmodal {
  display: flex;
  align-items: center;
}

.user-info-editcohortsmodal {
  display: flex;
  align-items: center;
}

.user-initials-editcohortsmodal {
  color: #fff;
  padding: 5px;
  border: 1px solid #eaeaea;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px; 
  font-size: 18px;
}

.user-details-editcohortsmodal {
  text-align: left;
}
.logout-button-editcohortsmodal {
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
  padding: 8px 5px;
  border-radius: 4px;
}

.user-name-editcohortsmodal {
  font-weight: bold;
  font-size: 14px;
}

.user-email-editcohortsmodal {
  color: #666;
  font-size: 12px;
}

.form-editcohortsmodal {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 20px;
  /* border-radius: 8px; */
}

/* .poster-preview {
  width: 150px;
  height: 150px;
  margin-bottom: 10px;
align-items: center;
} */
/** START CHANGE FOR "responsive cohort poster image"  --- **/
.poster-preview {
  width: 100%; /* Allow the image to scale with the container width */
  /* height: auto;  */
  height: 250px;
  max-width: 300px; /* Set a maximum width for larger screens */
  margin-bottom: 10px;
}
/** END CHANGE FOR "responsive cohort poster image"  --- **/

.button-group {
  display: flex;
  justify-content: space-between;
}

.update-button,
.cancel-button {
  background-color: #0183ff;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.cancel-button {
  background-color: #f44336;
}

.update-button:hover {
  background-color: #005bb5;
}

.cancel-button:hover {
  background-color: #d32f2f;
}

.change-poster-button-editcohortsmodal {
  background-color: #ffffff;
  color: #007bff;
  border: 1px solid #cccccc;
  cursor: pointer;
  margin-bottom: 10px;
}

.change-poster-button-editcohortsmodal:hover {
  background-color: #e6f7ff;
}

.update-button-editcohortsmodal,
.cancel-button-editcohortsmodal {
  background-color: #0183ff;
  color: white;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  width: 10%;
}
.update-button-editcohortsmodal,
.cancel-button-editcohortsmodal {
  padding: 12px 24px;
  font-size: 1em;
  border-radius: 5px;
  cursor: pointer;
}


.cancel-button-editcohortsmodal {
  background-color: #ffffff;
  color: #007bff;
  border: 1px solid #007bff;
  cursor: pointer;
}

.update-button-editcohortsmodal:hover {
  background-color: #0056b3;
}

.cancel-button-editcohortsmodal:hover {
  background-color: #e6f7ff;
}

.button-group-editcohortsmodal {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.form-editcohortsmodal label {
  font-weight:550;
  margin-bottom: 8px;
  font-size: 16px;
}

.form-editcohortsmodal input[type="text"],
.form-editcohortsmodal .quill {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 16px;
  font-size: 14px;
}

input[type="text"]:focus,
.form-editcohortsmodal .quill:focus {
  outline: none;
  border-color: #007bff;
}

/* .form-editcohortsmodal label + input[type="text"],
.form-editcohortsmodal label + .quill {
  margin-top: 1px;
} */

.form-editcohortsmodal section {
  margin-bottom: 20px;
}

.poster-preview {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  margin-bottom: 12px;
}

.button-group-editcohortsmodal {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 24px;
}

