.modal-container-addnewpipelinemodal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1000;
  }
  
  .modal-addnewpipelinemodal {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    width: 400px;
    max-width: 90%;
    animation: fadeInCustomPipelineModal 0.5s ease-out; /* START CHANGE FOR Keyframe Animations */
  }
  
  /* *** START CHANGE for closeModal *** */
.modal-header-addnewpipelinemodal-with-cross {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close-button-addnewpipelinemodal-cross {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: rgb(182, 182, 182);
}

.close-button-addnewpipelinemodal-cross:hover {
  color: #000;
  background-color: transparent;
}
/* *** END CHANGE for closeModal *** */

  .modal-title-addnewpipelinemodal {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .modal-label-addnewpipelinemodal {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 8px;
    display: block;
  }
  
  .modal-input-addnewpipelinemodal {
    width: 100%;
    padding: 10px;
    margin-bottom: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
  }
  
  .radio-group-addnewpipelinemodal {
    margin-bottom: 16px;
  }
  
  .radio-label-addnewpipelinemodal {
    font-size: 14px;
    margin-right: 20px;
  }
  
  .modal-buttons-addnewpipelinemodal {
    display: flex;
    justify-content:center;
    gap: 10px;
  }
  
  .btn-cancel-addnewpipelinemodal,
  .btn-submit-addnewpipelinemodal {
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
  }
  
  .btn-cancel-addnewpipelinemodal {
    background-color: #ffffff;
    color: #007bff;
    margin-right: 10px;
    border: 1px solid #007bff;

  }
  
  .btn-submit-addnewpipelinemodal {
    background-color: #007bff;
    color: white;
  }
  
  .btn-cancel-addnewpipelinemodal:hover {
    background-color: #e6f7ff;
}
  
  .btn-submit-addnewpipelinemodal:hover {
    background-color: #0056b3;
  }

  @keyframes fadeInCustomPipelineModal {
    from {
        opacity: 0;
        transform: scale(0.9);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}
  