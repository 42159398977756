.forgetpass-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: #f0f2f5;
  position: relative;
}

.logo-container {
  margin-bottom: 20px;
}

.logo {
  height: 65px;
  width: 70px;
}

.forgetpass-container {
  background: #fff;
  padding: 25px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1;
  width: 20%;
  margin-bottom: 200px;
  margin-top: 150px;
}

.form {
  display: flex;
  flex-direction: column;
}

.form-group {
   margin-bottom: 25px;
}

.form-group label {
  margin-bottom: 25px;
  font-weight: bold;
}

.form-group input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 10px;
}


.submit-btn:hover {
  background-color: #0056b3; /* Darker shade of blue for submit button on hover */
}

.cancel-btn:hover {
  background-color: #0056b3; /* Darker shade of blue for submit button on hover */
}

.submit-btn {
  background-color: #007bff; /* Blue color for submit button */
}

.cancel-btn {
  background-color: #6c757d; /* Gray color for cancel button */
   font-size: 16px;
}

.button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 20px; /* Add some margin between buttons and other elements */
  
}

.footer {
  margin-top: 20px;
  text-align: center;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.mail-sent-popup {
  background: white;
  padding: 30px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 20%;
  font-size: 16px;
}

.mail-sent-popup i {
  font-size: 2rem; /* Adjust the size of the icon */
  color: #0183ff; /* Adjust the color of the icon */
  margin-bottom: 10px;
}

.mail-sent-popup h2 {
  margin-bottom: 5px;
  font-weight: 700;
  font-size: 18px;
}

.mail-sent-popup p {
  margin-bottom: 29px;
}

.mail-sent-popup .button-group {
  display: flex;
  justify-content: space-between;
}

.mail-sent-popup .submit-btn {
  background-color: #007bff;
  color: white;
  width: 40%;

}

.mail-sent-popup .cancel-btn {
  background-color: #6c757d;
  color: white;
   width: 40%;
   font-size: 16px;
}

.mailsent{
    text-align: center;
    font-weight: 700;
}


button[type="submit"] {
    width: 33%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

button[type="cancel"] {
    width: 43%; 
    font-size: 16px;
}