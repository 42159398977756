


@keyframes fadeInCustomconfirmationmodal {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
.modal-overlay-confirmationmodal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(90, 90, 90, 0.5);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content-confirmationmodal {
  background: white;
  padding: 30px;
  border-radius: 8px;
  z-index: 10000;
  width: 600px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  animation: fadeInCustomconfirmationmodal 0.3s ease-in-out;
}

.modal-buttons-confirmationmodal {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.btn-green-confirmationmodal {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.btn-red-confirmationmodal {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.btn-green-confirmationmodal:hover {
  background-color: #218838;
}

.btn-red-confirmationmodal:hover {
  background-color: #c82333;
}
.modal-heading-confirmationmodal {
  font-size: 18px;
  font-weight: 600;
  color: #525252;
  font-family: "Inter", sans-serif;
  margin-bottom: 15px;
}

.comments-input-confirmationmodal {
  width: 100%;
  height: 150px;
  margin-top: 12px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: border-color 0.2s ease-in-out;
  resize: vertical;
  font-size: 14px;
  font-weight: 500;
  color: #787878;
  font-family: "Inter", sans-serif;
}
.comments-input-confirmationmodal:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}
.comments-input-confirmationmodal::-webkit-scrollbar {
  width: 6px;
}

.comments-input-confirmationmodal::-webkit-scrollbar-thumb {
  background-color: #007bff;
  border-radius: 5px;
  cursor: move;
}

@media (max-width: 768px) {
  .modal-content-confirmationmodal {
    width: 90%;
    padding: 25px;
  }

  .btn-green-confirmationmodal,
  .btn-red-confirmationmodal {
    width: 100px;
    font-size: 13px;
  }

  .comments-input-confirmationmodal {
    height: 140px;
  }
}

@media (max-width: 480px) {
  .modal-content-confirmationmodal {
    width: 95%;
    padding: 20px;
  }

  .modal-heading-confirmationmodal {
    font-size: 16px;
  }

  .modal-buttons-confirmationmodal {
    flex-direction: column;
    gap: 8px;
  }

  .btn-green-confirmationmodal,
  .btn-red-confirmationmodal {
    width: 100%;
    font-size: 12px;
    padding: 8px;
  }

  .comments-input-confirmationmodal {
    height: 130px;
  }
}











